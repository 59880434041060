<template>
  <b-table
    ref="refOrderListTable"
    :items="orders"
    responsive
    :fields="tableColumns"
    primary-key="id"
    :sort-by.sync="sortBy"
    show-empty
    :empty-text="$t('noRecords')"
    :sort-desc.sync="sortDesc"
    class="position-relative"
  >
    <template #head(invoiceStatus)>
      <feather-icon icon="TrendingUpIcon" class="mx-auto" />
    </template>

    <!-- Column: Id -->

    <!-- Column: Order Status -->
    <template #cell(invoiceStatus)="data">
      <b-tooltip :target="`invoice-row-${data.item.id}`" placement="top">
        <p class="mb-0">
          {{ data.item.invoiceStatus }}
        </p>
        <p class="mb-0">Balance: {{ data.item.balance }}</p>
        <p class="mb-0">Due Date: {{ data.item.dueDate }}</p>
      </b-tooltip>
    </template>

    <!-- Column: Client -->
    <!-- <template #cell(client)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.adduser)"
              :variant="`light-${resolveClientAvatarVariant(
                data.item.invoiceStatus
              )}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.client.name }}
          </span>
          <small class="text-muted">{{ data.item.client.companyEmail }}</small>
        </b-media>
      </template> -->

    <!-- Column: Issued Date -->
    <!-- <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

    <!-- Column: Balance -->
    <!-- <template #cell(balance)="data">
        <template v-if="data.value === 0">
          <b-badge pill variant="light-success"> Paid </b-badge>
        </template>
        <template v-else>
          {{ data.value }}
        </template>
      </template> -->

    <!-- Column: Actions -->
    <template #cell(actions)="data">
      <b-link
        class="text-nowrap"
        @click="
          $router.push({
            name: toRouteName,
            params: { id: data.item.id, plantid: plantid },
          })
        "
      >
        <span class="text-dark"> {{ $t("view") }}</span>
        <feather-icon
          :id="`invoice-row-${data.item.id}-preview-icon`"
          icon="EyeIcon"
          size="16"
          class="mx-1"
        />
        <!-- <b-tooltip
          title="Preview Order"
          :target="`invoice-row-${data.item.id}-preview-icon`"
        /> -->
      </b-link>
    </template>
  </b-table>
</template>

<script>
import { BTable, BLink, BTooltip } from "bootstrap-vue";

export default {
  components: {
    BTable,
    BLink,
    BTooltip,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    orders: null,
    // eslint-disable-next-line vue/require-default-prop
    sortDesc: null,
    tableColumns: {
      type: Array,
      required: true,
    },
    toRouteName: {
      type: String,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    plantid: {
      type: String,
      required: false,
      default: null,
    },
  },
  async mounted() {},
  methods: {},
};
</script>
